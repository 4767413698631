import React, { ChangeEvent, useRef, useState } from "react";
import { CompanyViewModel } from "../../api/companyCollection.api";
import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import Background from "../base/Background";
import Image from "../commons/Image";
import { Option } from "../../types/common";
import {
  municipalityToView,
  removePrefixPhoneNumber,
  serializeOrganizationNumber,
  serializePhoneNumber,
  serializePostalCode,
} from "../../utils/common";
import RequiredTextField from "../commons/RequiredTextField";
import OrganizationNumberField from "../commons/OrganizationNumberField";
import RequiredTextArea from "../commons/RequiredTextArea";
import EmailTextField from "../commons/EmailTextField";
import PhoneNumberTextField from "../commons/PhoneNumberField";
import KommunAutoCompleteField from "../commons/KommunAutoCompleteField";
import PostalCodeTextField from "../commons/PostalCodeTextField";
import useCompany from "../../hooks/useCompany";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebase";
import {
  isValidEmail,
  isValidOrganiationNumber,
  isValidPhoneNumber,
  isValidPostalCode,
} from "../../utils/validations";

interface Props {
  companyProfile: CompanyViewModel;
}

const EditCompanyProfile = ({ companyProfile }: Props) => {
  const { patchCompanyProfile } = useCompany();

  const [user] = useAuthState(auth);

  const [companyName, setCompanyName] = useState(companyProfile.name);
  const [organizationNumber, setOrganizationNumber] = useState(
    companyProfile.organizationNumber
  );
  const [industry, setIndustry] = useState(companyProfile.industry);
  const [praoPositions, setPraoPositions] = useState(
    companyProfile.numberOfPraoPositions.toString()
  );
  const [about, setAbout] = useState(companyProfile.about);
  const [praoTasksDescription, setPraoTasksDescription] = useState(
    companyProfile.praoTasksDescription
  );
  const [contactPersonFirstName, setContactPersonFirstName] = useState(
    companyProfile.contactPersonFirstName
  );
  const [contactPersonLastName, setContactPersonLastName] = useState(
    companyProfile.contactPersonLastName
  );
  const [contactEmail, setContactEmail] = useState(companyProfile.contactEmail);
  const [contactPhone, setContactPhone] = useState(
    removePrefixPhoneNumber(companyProfile.contactPhone)
  );
  const [selectedKommun, setSelectedKommun] = useState<Option<string> | null>({
    label: municipalityToView(companyProfile.municipality),
    id: companyProfile.municipality,
  });
  const [postalCode, setPostalCode] = useState(companyProfile.postalCode);
  const [city, setCity] = useState(companyProfile.postalTown);
  const [street, setStreet] = useState(companyProfile.street);

  const [companyNameError, setCompanyNameError] = useState<null | string>(null);
  const [organizationNumberError, setOrganizationNumberError] = useState<
    null | string
  >(null);
  const [industryError, setIndustryError] = useState<null | string>(null);
  const [praoPositionsError, setPraoPositionsError] = useState<null | string>(
    null
  );
  const [aboutError, setAboutError] = useState<null | string>(null);
  const [praoTasksDescriptionError, setPraoTasksDescriptionError] = useState<
    null | string
  >(null);
  const [contactPersonFirstNameError, setContactPersonFirstNameError] =
    useState<null | string>(null);
  const [contactPersonLastNameError, setContactPersonLastNameError] = useState<
    null | string
  >(null);
  const [contactEmailError, setContactEmailError] = useState<null | string>(
    null
  );
  const [contactPhoneError, setContactPhoneError] = useState<null | string>(
    null
  );
  const [kommunError, setKommunError] = useState<null | string>(null);
  const [postalCodeError, setPostalCodeError] = useState<null | string>(null);
  const [cityError, setCityError] = useState<null | string>(null);
  const [streetError, setStreetError] = useState<null | string>(null);

  const [runValidation, setRunValidation] = useState<boolean | "true">(false);

  const navigate = useNavigate();

  const handleIndustryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (industryError !== null) {
      setIndustryError(null);
    }
    const { value } = event.target;
    setIndustry(value);
  };

  const handlePraoPositionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (praoPositionsError !== null) {
      setPraoPositionsError(null);
    }
    const { value } = event.target;
    setPraoPositions(value);
  };

  const validateIndustry = (industry: string) => {
    const isValid = industry.length > 0;
    if (isValid === false) {
      setIndustryError("Du måste ange en bransch.");
    } else {
      setIndustryError(null);
    }
    return isValid;
  };

  const validatePraoPositions = (praoPositions: string) => {
    const isValid = praoPositions.trim() !== "";
    if (isValid === false) {
      setPraoPositionsError(
        "Du måste ange hur många praktikanter ni kan ta emot."
      );
    } else {
      setPraoPositionsError(null);
    }
    return isValid;
  };

  const triggerRunValidation = () => {
    if (runValidation === true) {
      setRunValidation("true");
    }
    if (runValidation === false) {
      setRunValidation(true);
    }
    if (runValidation === "true") {
      setRunValidation(true);
    }
  };

  const runUpdateCompanyProfile = async () => {
    const token = await user?.getIdToken();

    await patchCompanyProfile(
      {
        id: companyProfile.id,
        name: companyName,
        organizationNumber: serializeOrganizationNumber(organizationNumber),
        industry: industry,
        numberOfPraoPositions: parseInt(praoPositions),
        about: about,
        praoTasksDescription: praoTasksDescription,
        contactPersonFirstName: contactPersonFirstName,
        contactPersonLastName: contactPersonLastName,
        contactEmail: contactEmail,
        contactPhone: serializePhoneNumber(contactPhone),
        municipality: selectedKommun!.id,
        postalCode: serializePostalCode(postalCode),
        postalTown: city,
        street: street,
      },
      token
    );
    navigate("/profil");
  };

  const formValidator = () => {
    const validIndustry = validateIndustry(industry);
    const validPraoPositions = validatePraoPositions(praoPositions);
    const isValid =
      validIndustry &&
      validPraoPositions &&
      validIndustry &&
      companyName.length > 0 &&
      about.length >= 10 &&
      praoTasksDescription.length >= 10 &&
      street.length > 0 &&
      city.length > 0 &&
      isValidEmail(contactEmail) &&
      isValidPostalCode(postalCode) &&
      selectedKommun !== null &&
      contactPersonFirstName.length > 0 &&
      contactPersonLastName.length > 0 &&
      isValidPhoneNumber(contactPhone) &&
      isValidOrganiationNumber(organizationNumber);
    return isValid;
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    triggerRunValidation();

    const formIsValid = formValidator();

    if (formIsValid === false) {
      return;
    }

    runUpdateCompanyProfile();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
      <Card sx={{ borderRadius: "20px", paddingBottom: "20px" }}>
        <Background height="200px">
          <></>
        </Background>
        <Box
          sx={{
            paddingX: "40px",
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "200px",
              height: "200px",
              overflow: "hidden",
              marginTop: "-120px",
              background: "white",
              borderRadius: "50%",
              border: "5px solid white",
            }}
          >
            {companyProfile.logoUrl && (
              <Image
                src={companyProfile.logoUrl}
                padding="10px"
                maxWidth="100%"
                maxHeight="100%"
                width="auto"
                height="auto"
                objectFit="contain"
                margin="auto"
              />
            )}
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <RequiredTextField
                  value={companyName}
                  setValue={setCompanyName}
                  label="Företagsnamn"
                  name="Företagsnamn"
                  runValidation={runValidation}
                  helperText="Du måste ange ett företagsnamn."
                  valueError={companyNameError}
                  setValueError={setCompanyNameError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <OrganizationNumberField
                  organizationNumber={organizationNumber}
                  setOrganizationNumber={setOrganizationNumber}
                  label="Organisationsnummer"
                  name="organization-number"
                  runValidation={runValidation}
                  organizationNumberError={organizationNumberError}
                  setOrganizationNumberError={setOrganizationNumberError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Bransch"
                  name="industry"
                  value={industry}
                  onChange={handleIndustryChange}
                  error={!!industryError}
                  helperText={industryError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Tillgängliga praktikplatser per termin"
                  name="number-of-prao-positions"
                  type="number"
                  value={praoPositions ?? ""}
                  onChange={handlePraoPositionsChange}
                  error={!!praoPositionsError}
                  helperText={praoPositionsError}
                  inputProps={{
                    min: 0,
                    step: 1,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      {/* <Card sx={{ borderRadius: "20px", paddingY: "20px" }}>
      <Box
        sx={{
          paddingX: "40px",
          display: "flex",
          flexDirection: "column",
          rowGap: "10px",
        }}
      >
         <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
          Hashtags
        </Typography>
        <HashtagList hashtags={companyProfile.hashtags} />
      </Box>
    </Card> */}
      <Card sx={{ borderRadius: "20px", paddingY: "20px" }}>
        <Box
          sx={{
            paddingX: "40px",
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
          }}
        >
          <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
            Kortfattat om vad ni gör på företaget
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <RequiredTextArea
                value={about}
                setValue={setAbout}
                label="Kortfattat om vad ni gör på företaget"
                displayLabel={false}
                name="about"
                helperText="Du måste ange en kort beskrivning på minst 10 tecken av företaget."
                minLength={10}
                runValidation={runValidation}
                valueError={aboutError}
                setValueError={setAboutError}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card sx={{ borderRadius: "20px", paddingY: "20px" }}>
        <Box
          sx={{
            paddingX: "40px",
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
          }}
        >
          <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
            Vilka arbetsuppgifter kommer en praktikant få göra hos er?
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <RequiredTextArea
                value={praoTasksDescription}
                setValue={setPraoTasksDescription}
                label="Vilka arbetsuppgifter kommer en praktikant få göra hos er?"
                displayLabel={false}
                name="prao-tasks-description"
                helperText="Du måste ange en kort beskrivning på minst 10 tecken av vad praktikanterna kommer att göra hos er."
                minLength={10}
                runValidation={runValidation}
                valueError={praoTasksDescriptionError}
                setValueError={setPraoTasksDescriptionError}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card sx={{ borderRadius: "20px", paddingY: "20px" }}>
        <Box
          sx={{
            paddingX: "40px",
            display: "flex",
            flexDirection: { sm: "colum", xs: "column" },
            rowGap: { sm: "20px", xs: "20px" },
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
          >
            <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
              Kontaktinformation
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <RequiredTextField
                  value={contactPersonFirstName}
                  setValue={setContactPersonFirstName}
                  label="Förnamn på kontaktperson"
                  name="contact-person-first-name"
                  helperText="Du måste ange ett förnamn."
                  runValidation={runValidation}
                  valueError={contactPersonFirstNameError}
                  setValueError={setContactPersonFirstNameError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RequiredTextField
                  value={contactPersonLastName}
                  setValue={setContactPersonLastName}
                  label="Efternamn på kontaktperson"
                  name="contact-person-last-name"
                  helperText="Du måste ange ett efternamn."
                  runValidation={runValidation}
                  valueError={contactPersonLastNameError}
                  setValueError={setContactPersonLastNameError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EmailTextField
                  email={contactEmail}
                  setEmail={setContactEmail}
                  label="Epost för kontakt"
                  runValidation={runValidation}
                  emailError={contactEmailError}
                  setEmailError={setContactEmailError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneNumberTextField
                  phoneNumber={contactPhone}
                  setPhoneNumber={setContactPhone}
                  label="Telefon för kontakt"
                  name="contact-phone"
                  runValidation={runValidation}
                  phoneNumberError={contactPhoneError}
                  setPhoneNumberError={setContactPhoneError}
                />
              </Grid>
            </Grid>
            <Typography
              variant="h3"
              sx={{ fontSize: "1.2rem", marginTop: "20px" }}
            >
              Address
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
            }}
          >
            <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
              Adress
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <KommunAutoCompleteField
                  selectedKommun={selectedKommun}
                  setSelectedKommun={setSelectedKommun}
                  label="Kommun"
                  name="kommun"
                  runValidation={runValidation}
                  kommunError={kommunError}
                  setKommunError={setKommunError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PostalCodeTextField
                  postalCode={postalCode}
                  setPostalCode={setPostalCode}
                  label="Postnummer"
                  runValidation={runValidation}
                  postalCodeError={postalCodeError}
                  setPostalCodeError={setPostalCodeError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RequiredTextField
                  value={city}
                  setValue={setCity}
                  label="Postort"
                  name="city"
                  helperText="Du måste ange en postort."
                  runValidation={runValidation}
                  valueError={cityError}
                  setValueError={setCityError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RequiredTextField
                  value={street}
                  setValue={setStreet}
                  label="Gata"
                  name="street"
                  helperText="Du måste ange en gata."
                  runValidation={runValidation}
                  valueError={streetError}
                  setValueError={setStreetError}
                />
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" sx={{ columnGap: "10px" }}>
            <Button sx={{ flex: 1 }} variant="contained" onClick={handleSubmit}>
              Spara
            </Button>
            <Button
              sx={{ flex: 1 }}
              variant="outlined"
              onClick={() => navigate("/profil")}
            >
              Avbryt
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default EditCompanyProfile;
