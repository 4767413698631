import React, { useContext, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AccountLoadingContext } from "../context/accountLoadingContext";

const ThankRegistrationCompanyScreen = () => {
  const { accountLoading, setAccountLoading } = useContext(
    AccountLoadingContext
  );

  useEffect(() => {
    if (accountLoading) {
      setAccountLoading(false);
    }
  }, [accountLoading]);

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "30px",
          gap: "15px",
          rowGap: "15px",
          maxWidth: "800px",
          margin: "auto",
          justifyContent: "center",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <Typography variant="h2" fontSize={"2em"}>
          Tack för att du registrerat dig!
        </Typography>
        <Button
          onClick={() => {
            navigate("/profil");
          }}
        >
          Gå till tjänsten
        </Button>
      </Box>
    </Box>
  );
};

export default ThankRegistrationCompanyScreen;
