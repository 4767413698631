import React, { useState } from "react";
import { useHeaders } from "./useHeaders";
import axios, { AxiosError } from "axios";
import { getAppCheckToken } from "../utils/appCheck";
import {
  CompanyViewModel,
  CreateCompanyRequest,
  UpdateCompanyRequest,
} from "../api/companyCollection.api";
import useConsent from "./useConsent";

const useCompany = () => {
  const { consent } = useConsent();
  const { baseHeaders } = useHeaders();
  const [loadingCompany, setLoading] = useState(false);
  const [errorCompany, setError] = useState<Error | null>(null);

  const postCompany = async (request: CreateCompanyRequest, token?: string) => {
    let headers = baseHeaders;

    if (headers === null && token) {
      headers = await (async () => {
        const headers = {
          "X-Firebase-AppCheck": await getAppCheckToken(
            process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string,
            consent !== false && consent !== undefined
          ),
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        return headers;
      })();
    }

    if (headers !== null) {
      try {
        setLoading(true);
        await axios.post(
          `${process.env.REACT_APP_API_URL}/companies`,
          request,
          {
            headers: headers,
          }
        );
        setLoading(false);
      } catch (err) {
        setError(err as Error);
      }
    }
  };

  const patchCompanyProfile = async (
    request: UpdateCompanyRequest,
    token?: string
  ) => {
    let headers = baseHeaders;

    if (headers === null && token) {
      headers = await (async () => {
        const headers = {
          "X-Firebase-AppCheck": await getAppCheckToken(
            process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string,
            consent !== false && consent !== undefined
          ),
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        return headers;
      })();
    }

    if (headers !== null) {
      try {
        setLoading(true);
        await axios.patch(
          `${process.env.REACT_APP_API_URL}/companies/${request.id}`,
          request,
          {
            headers: headers,
          }
        );
        setLoading(false);
      } catch (err) {
        setError(err as Error);
      }
    }
  };

  const getCompany = async (
    id: string,
    token?: string
  ): Promise<CompanyViewModel | undefined> => {
    let headers = baseHeaders;

    if (headers === null && token) {
      headers = await (async () => {
        const headers = {
          "X-Firebase-AppCheck": await getAppCheckToken(
            process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string,
            consent !== false && consent !== undefined
          ),
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        return headers;
      })();
    }

    if (headers !== null) {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/companies/${id}`,
          {
            headers: headers,
          }
        );
        setLoading(false);
        return data as CompanyViewModel;
      } catch (err) {
        const error = err as AxiosError;
        if (error.response?.status === 404) {
          return undefined;
        } else {
          setError(err as Error);
        }
      }
    }
  };

  return {
    getCompany,
    postCompany,
    patchCompanyProfile,
    loadingCompany,
    errorCompany,
  };
};

export default useCompany;
